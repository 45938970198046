import { firestoreAction } from 'vuexfire'
import { db } from '@/firebaseConfig'

export default {
  namespaced: true,
  state: {
    parameters: null,
    positions: [],
    candidates1: [],
    candidates2: [],
  },
  getters: {
    getParameter: state => paramSearch => {
      const result = state.parameters.filter(parameter => parameter.id === paramSearch)
      return result.length ? result.pop() : false
    },
  },
  mutations: {
  },
  actions: {
    bindParameters: firestoreAction(({ bindFirestoreRef }) => {
      bindFirestoreRef('parameters', db.collection('parameters'))
    }),

    bindPositions: firestoreAction(({ bindFirestoreRef }) => {
      bindFirestoreRef('positions', db.collection('positions'))
    }),

    bindCandidates1: firestoreAction(({ bindFirestoreRef }) => {
      bindFirestoreRef('candidates1', db.collection('candidates')
        .where('positionId', '==', 1))
    }),

    unbindCandidates1: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('candidates1')),

    bindCandidates2: firestoreAction(({ bindFirestoreRef }) => {
      bindFirestoreRef('candidates2', db.collection('candidates')
        .where('positionId', '==', 2))
    }),

    unbindCandidates2: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('candidates2')),

  },
}
