import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import fastway from './fastway'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    fastway,
  },
  mutations: {
    ...vuexfireMutations,
  },
  strict: process.env.DEV,
})
