import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCg8PpQ3kHK7FBo5bN1ahzQI5hAH5QWDyE',
  authDomain: 'iepc-apps.firebaseapp.com',
  projectId: 'iepc-apps',
  storageBucket: 'iepc-apps.appspot.com',
  messagingSenderId: '1097893902127',
  appId: '1:1097893902127:web:9f1fcf00d7bf85985493da',
  measurementId: 'G-2704FLX9C8',
}

export const app = firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()
